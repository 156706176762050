import { ReactElement } from "react";
import "../styles/navbar.css";

interface NavProps {
  logo: ReactElement;
}

export default function NavbarFrancaHealth({ logo }: NavProps) {
  const handleScrollToContact = () => {
    const targetSection = document.getElementById("sectionContact");
    if (targetSection) {
      const yOffset = -35; // Ajuste de desplazamiento
      const y =
        targetSection.getBoundingClientRect().top + window.scrollY + yOffset; // Usando scrollY

      // Realizar el scroll suave hacia la posición calculada
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    } else {
      console.error("Element not found: sectionContact");
    }
  };

  return (
    <div className="navbarfranca">
      <div className="navbar_EST">EST. 2013</div>

      <div className="navbar_title">{logo}</div>
      <div className="navbar_contact">
        <div className="navbar_contact_list">
          <div>
            <a
              className="navbar_contact_list_enlace_wpp"
              href="https://wa.link/bentml"
              target="_blank"
            >
              <span>whatsapp</span>
            </a>
          </div>
          <div className="divider"></div>
          <div>
            <a
              className="navbar_contact_list_enlace_agendece"
              href="https://calendar.app.google/FQgnjouCr4xApAPe7"
              target="_blank"
            >
              <span>agendese</span>
            </a>
          </div>
          <div className="divider"></div>
          <div onClick={handleScrollToContact}>
            <span>contacto</span>
          </div>
        </div>
      </div>
    </div>
  );
}
