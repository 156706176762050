export const brands = [
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_galderma.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_restylane.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_sculptra.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_alastin.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_cetaphil.png",
    link: "",
  },

  {
    url: "",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_novo.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_rybelsus.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_saxenda.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_cambiando.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_esperoct.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_novoseven.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_novoeight.png",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_novocare.png",
    link: "",
  },

  {
    url: "",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_FK.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_Fresubin.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_diben.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_frebini.png",
    link: "",
  },
  {
    url: "",
    link: "",
  },

  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_heel.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_enterobalance.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_spascupreel.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_renheel.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_kliheel.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_nervoheel.png",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_hormeel.png",
    link: "",
  },
  {
    url: "",
    link: "",
  },
  {
    url: "/assets/BrandsHealth/FRANCA_landing_page_logos_boiiron.png",
    link: "",
  },
];
