import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "../styles/modalPolitics.css";

interface ModalPoliticPorps {
  availablePolitics: () => void;
}
export default function ModalPolitics({
  availablePolitics,
}: ModalPoliticPorps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePolitics = () => {
    availablePolitics();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <a onClick={handleClickOpen} className={"enlaceToPolitic"}>
        Ver terminos y condiciones
      </a>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="/assets/franca_logo_politics.png"
                    alt=""
                    style={{ width: "25%" }}
                  />
                </div>
                <br />

                <h4 style={{ textAlign: "center" }}>
                  <b>
                    FRANCA<span className="registered">&#174;</span> Agency -
                    POLÍTICA DE PRIVACIDAD
                  </b>
                </h4>
              </div>
            </div>
            <br />
            La presente Política de Privacidad establece los términos en que
            <b> franca.agency</b>, usa y protege la información que es
            proporcionada por sus usuarios al momento de utilizar su sitio web.
            Esta empresa está comprometida con la seguridad de los datos de sus
            usuarios. Cuando le pedimos llenar los campos de información
            personal con la cual usted pueda ser identificado, lo hacemos
            asegurando que solo se empleará de acuerdo con los términos de este
            documento. Sin embargo esta Política de Privacidad puede cambiar con
            el tiempo o ser actualizada por lo que le recomendamos y enfatizamos
            revisar continuamente esta página para asegurarse que está de
            acuerdo con dichos cambios.
            <br />
            <br />
            <h5>
              <b>INFORMACIÓN QUE ES RECOGIDA:</b>
            </h5>
            Nuestro sitio web podrá recoger información personal por ejemplo:
            Nombre, información de contacto como su dirección de correo
            electrónico, número de celular o información demográfica. Así mismo
            cuando sea necesario podrá ser requerida información específica para
            contactarnos con usted, realizar una entrega o facturación.
            <br />
            <br />
            <h5>
              <b>USO DE LA INFORMACIÓN RECOGIDA:</b>
            </h5>
            Nuestro sitio web emplea la información con el fin de proporcionar
            el mejor servicio posible, particularmente para mantener un registro
            de usuarios, mantenernos en contacto con usted o su compañía y
            mejorar nuestros servicios. Es posible que sean enviados correos
            electrónicos periódicamente a través de nuestro sitio con
            información publicitaria que consideremos relevante para usted o que
            pueda brindarle algún beneficio, estos correos electrónicos serán
            enviados a la dirección que usted proporcione y podrán ser
            cancelados en cualquier momento.
            <br />
            FRANCA® Agency, a través de su sitio web <b> franca.agency</b>, está
            altamente comprometido para cumplir con el compromiso de mantener su
            información segura. Usamos los sistemas más avanzados y los
            actualizamos constantemente para asegurarnos que no exista ningún
            acceso no autorizado.
            <br />
            <br />
            <h5>
              <b>COOKIES:</b>
            </h5>
            Una cookie se refiere a un fichero que es enviado con la finalidad
            de solicitar permiso para almacenarse en su ordenador, al aceptar
            dicho fichero se crea y la cookie sirve entonces para tener
            información respecto al tráfico web, y también facilita las futuras
            visitas a una web recurrente. Otra función que tienen las cookies es
            que con ellas las web pueden reconocerte individualmente y por tanto
            brindarte el mejor servicio personalizado de su web. Nuestro sitio
            web emplea las cookies para poder identificar las páginas que son
            visitadas y su frecuencia. Esta información es empleada únicamente
            para análisis estadístico y después la información se elimina de
            forma permanente. Usted puede eliminar las cookies en cualquier
            momento desde su ordenador. Sin embargo las cookies ayudan a
            proporcionar un mejor servicio de los sitios web, estás no dan
            acceso a información de su ordenador ni de usted, a menos de que
            usted así lo quiera y la proporcione directamente, visitas a una web
            . Usted puede aceptar o negar el uso de cookies, sin embargo la
            mayoría de navegadores aceptan cookies automáticamente pues sirve
            para tener un mejor servicio web. También usted puede cambiar la
            configuración de su ordenador para declinar las cookies. Si se
            declinan es posible que no pueda utilizar algunos de nuestros
            servicios.
            <br />
            <br />
            <h5>
              <b>ENLACES A TERCEROS:</b>
            </h5>
            Este sitio web pudiera contener enlaces a otros sitios que pudieran
            ser de su interés. Una vez que usted de clic en estos enlaces y
            abandone nuestra página, ya no tenemos control sobre al sitio al que
            es redirigido y por lo tanto no somos responsables de los términos o
            privacidad ni de la protección de sus datos en esos otros sitios
            terceros. Dichos sitios están sujetos a sus propias políticas de
            privacidad por lo cual es recomendable que los consulte para
            confirmar que usted está de acuerdo con estas.
            <br />
            <br />
            <h5>
              <b>CONTROL DE SU INFORMACIÓN PERSONAL:</b>
            </h5>
            En cualquier momento usted puede restringir la recopilación o el uso
            de la información personal que es proporcionada a nuestro sitio web.
            Cada vez que se le solicite rellenar un formulario, como el de alta
            de usuario, puede marcar o desmarcar la opción de recibir
            información por correo electrónico. En caso de que haya marcado la
            opción de recibir nuestro boletín o publicidad usted puede
            cancelarla en cualquier momento. Esta compañía no venderá, cederá ni
            distribuirá la información personal que es recopilada sin su
            consentimiento, salvo que sea requerido por un juez con un orden
            judicial. <b> franca.agency</b>: Se reserva el derecho de cambiar
            los términos de la presente Política de Privacidad en cualquier
            momento.
            <br />
            <br />
            <h5>
              <b>TÉRMINOS DEL SERVICIO:</b>
            </h5>
            Para todos los efectos transaccionales y legales, será
            <b> franca.agency</b>, agencia de marketing, sujeta a las leyes
            colombianas, con existencia legal y domicilio principal en la ciudad
            de Bogotá D.C, en adelante <b> franca.agency </b>. Las transacciones
            que se efectúen a través del sitio <b> franca.agency </b>(el
            “Sitio”) se sujetan a los presentes términos y condiciones (los
            “Términos y Condiciones”) y particularmente lo previsto para las
            ventas a distancia por las disposiciones colombianas aplicables a la
            materia. Al realizar una transacción en el Sitio, se entiende el
            conocimiento y aceptación de los Términos y Condiciones descritos a
            continuación. Igualmente, cualquier usuario que visite el Sitio
            entiende que conoce y acepta todas y cada una de las condiciones de
            estos Términos y Condiciones. Este se referirá a “Clientes” tanto a
            los visitantes del Sitio, a los potenciales compradores, como a los
            compradores.
            <br />
            <br />
            <h5>
              <b>CAPTURA DE DATOS-REGISTRO DE CLIENTE:</b>
            </h5>
            <b> franca.agency</b> opera el Sitio, mediante el cual los clientes
            podrán registrarse para ser contactados bien sea personalmente o a
            un delegado de la compañía a la que representa. El registro del
            Cliente en este Sitio es indispensable para que este pueda ser
            contactado por FRANCA<span className="registered">&#174;</span>
            Agency. Para ello, el Cliente debe registrar en la página de
            registro, sus datos básicos incluyendo información personal como el
            nombre, apellido y el correo electrónico e información corporativa
            como la empresa y el correo empresarial. El Cliente al ingresar sus
            datos declara de buena fe que los mismos corresponden a información
            veraz y vigente. Los datos de los clientes serán tratados de acuerdo
            con las políticas de tratamiento de la información de{" "}
            <b> franca.agency</b> que se integran a los presentes términos y
            condiciones. El registro en el sitio por su parte como cliente,
            deberán ser realizados por personas capaces de conformidad con la
            ley colombiana exclusivamente. La información de registro de los
            Clientes será recolectada, almacenada y utilizada por
            <b> franca.agency</b>, manteniendo los estándares de seguridad
            suficientes para impedir cualquier adulteración, pérdida, consulta,
            uso o acceso no autorizado o fraudulento, y garantizando los
            derechos de los titulares de los datos. La información solicitada en
            el registro tiene por objeto la inscripción del Cliente para acceder
            al Sitio y eventualmente, adquirir los artículos ofrecidos a través
            del Sitio. La información recolectada será utilizada con propósitos
            estadísticos y promocionales. Por razones de seguridad en la
            transacción y proteger a sus clientes, <b> franca.agency</b> se
            reserva el derecho de solicitar datos adicionales con el fin de
            corroborar datos personales así como también no procesar temporal o
            definitivamente la acciones realizadas por aquellos clientes cuyos
            datos no hayan podido ser confirmados y el de eliminar cualquier
            registro previamente aceptado o rechazar una nueva solicitud, sin
            que esté obligado a comunicar o exponer las razones de su decisión y
            sin que ello genere derecho para el cliente a solicitar de su parte,
            el resarcimiento de perjuicios.
            <br /> <br />
            <h5>
              <b>CONTACTO</b>
            </h5>
            <b> franca.agency</b>
            <br />
            <ul>
              <li>
                <a href="https://wa.link/bentml" target="_blank">
                  Teléfono
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/franca.agency/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/franca-agency/"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@franca.agency" target="_blank">
                  Tik Tok
                </a>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cerrar
          </Button>
          <Button onClick={handlePolitics} color="success" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
