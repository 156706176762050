import React from "react";
import { brands } from "../../constants/brands";
import "../styles/slider.css";

export default function SliderBrands() {
  return (
    <div className="slider">
      <div className="move">
        {brands.map((item) => {
          return (
            <>
              {item.url ? (
                <div className="box">
                  <img src={item.url} alt="" />
                </div>
              ) : (
                <img src="/assets/BrandsHealth/separator.png" alt="" />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
