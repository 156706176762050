import InstagramIcon from "../../icons/instagram";
import LinkedinIcon from "../../icons/linkedin";
import TiktokIcon from "../../icons/tiktok";
import "../styles/footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__brand">
          <div className="footer__brand-superTitle">
            <img
              src="/assets/Logos/Logo Franca General.png"
              alt=""
              width={300}
              height={110}
            />
          </div>

          <p className="footer__brand-description">
            Franca
            <span className="registered" style={{ marginRight: "2px" }}>
              &#174;
            </span>
            Agency es una agencia de publicidad especializada en pharma &
            healthcare enfocada en estrategia, creatividad y digital.
          </p>
          <div className="footer__social-icons">
            <a
              href="https://www.linkedin.com/company/franca-agency/"
              className="footer__social-link"
              target="_blank"
            >
              <LinkedinIcon color="#fff" size={25} />
            </a>
            <a
              href="https://www.tiktok.com/@franca.agency"
              className="footer__social-link"
              target="_blank"
            >
              <TiktokIcon color="#fff" size={25} />
            </a>
            <a
              href=" https://www.instagram.com/franca.agency/"
              className="footer__social-link"
              target="_blank"
            >
              <InstagramIcon color="#fff" size={25} />
            </a>
          </div>
        </div>

        <div className="footer__locations">
          <div className="footer__location">
            <h3 className="footer__location-title">COLOMBIA</h3>
            <p className="footer__location-modules">
              Calle 98 # 10 46 <br /> Oficina 304 / 401 <br /> Bogotá, Colombia{" "}
              <br />
              <a
                href="mailto:hola@franca.agency"
                className="footer__location-link"
              >
                hola@franca.agency
              </a>
              <br />
              +57 312 466 3076
            </p>

            <p>
              <a
                href="https://www.linkedin.com/company/franca-agency/"
                className="footer__location-link"
                target="_blank"
              >
                LinkedIn
              </a>
            </p>
          </div>

          <div className="footer__location">
            <h3 className="footer__location-title">MÉXICO</h3>
            <p className="footer__location-modules">
              Av. Insurgentes Sur 1079, <br /> Col del Valle Centro, <br />{" "}
              Benito Juárez
              <br /> Ciudad de México, C.P. 03100 <br /> Oficina: 06-103 DD07{" "}
              <br />
              <a
                href="mailto:hola@franca.agency"
                className="footer__location-link"
              >
                hola@franca.agency
              </a>
              <br />
              +57 312 466 3076
            </p>

            <p>
              <a
                href="https://www.linkedin.com/company/franca-agency/"
                target="_blank"
                className="footer__location-link"
              >
                LinkedIn
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
