import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ModalType } from "../../types/ModalTypes";
import "react-multi-carousel/lib/styles.css";
import "../styles/modal.css";
import CarruselHealth from "../carrusel/carruselHealth";
import 'bootstrap/dist/css/bootstrap.min.css'; 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "initial",
    boxShadow: "none",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiIconButton-root": {
    display: "none",
  },
}));

interface PropsModal {
  dataSlider: ModalType;
}

export default function ModalSlider(props: PropsModal) {
  const [open, setOpen] = React.useState(false);

  const { dataSlider } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div>
        <img src={dataSlider.name} alt="" onClick={handleClickOpen} />
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <CarruselHealth img={dataSlider.slide} />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
