import { ModalType } from "../types/ModalTypes";

export const sliderHealth: ModalType[] = [
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Cetaphil 01.jpg",
    slide: [
      "/assets/DIGITAL CAMP/Franca_DC_2024_caso_exito_vertical_1.png",
      "/assets/DIGITAL CAMP/Franca_DC_2024_caso_exito_vertical_2.png",
      "/assets/DIGITAL CAMP/Franca_DC_2024_caso_exito_vertical_3.png",
    ],
  },
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Digital camp.jpg",
    slide: [
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL_Mesa de trabajo 1 copia.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-03.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-04.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-05.jpg",
    ],
  },

  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__FRESENIUS KABI.jpg",
    slide: [
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-03.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-04.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL-05.jpg",
      "/assets/FK/FK_FRANCA_CAMPAÑA_FK_OP_FINAL_Mesa de trabajo 1 copia.jpg",
    ],
  },

  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__tu salud no es un juego .jpg",
    slide: [
      "/assets/TU SALUD NO ES UN JUEGO/tu-salud-no-es-un-juego-landing-1.jpg",
      "/assets/TU SALUD NO ES UN JUEGO/tu-salud-no-es-un-juego-landing-2.jpg",
      "/assets/TU SALUD NO ES UN JUEGO/tu-salud-no-es-un-juego-landing-3.jpg",
    ],
  },
];
