import React from "react";
import Carousel from "react-bootstrap/Carousel";

interface CarruselHealthProps {
  img: string[];
}

export default function CarruselHealth(props: CarruselHealthProps) {
  return (
    <Carousel>
      {props.img.map((image) => {
        return (
          <Carousel.Item>
            <img src={image} alt="" height={700} style={{width: '100%'}}/>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
