import React from "react";
import TextField from "@mui/material/TextField";
import "../styles/formContact.css";
import { Button, Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios, { AxiosError } from "axios";
import ModalPolitics from "../politics/modalPolitic";

interface dataEmailFranca {
  name: string;
  company: string;
  email: string;
  francaAgency: string;
  message: string;
  politics: boolean;
}

const agencyOptions = [
  {
    value: "Franca Agency",
    label: "AGENCY",
    color: "#000",
  },
  {
    value: "Franca CREATIVE",
    label: "CREATIVE",
    color: "#3ce08a",
  },
  {
    value: "Franca DIGITAL",
    label: "DIGITAL",
    color: "#c119ff",
  },
  {
    value: "Franca INFLUENCE",
    label: "INFLUENCE",
    color: "#2247cf",
  },
  {
    value: "Franca AUDIOVISUAL",
    label: "AUDIOVISUAL",
    color: "#ff2e00",
  },
  {
    value: "Franca EXPERIENCIAL",
    label: "EXPERIENCIAL",
    color: "#ffaef9",
  },
];

export default function FormContact() {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<dataEmailFranca>({
    defaultValues: {
      name: "",
      company: "",
      email: "",
      francaAgency: "",
      message: "",
      politics: false,
    },
  });

  const availablePolitics = () => {
    setValue("politics", true);
  };
  const submitEmail = async (data: dataEmailFranca) => {
    const dataAdapter = {
      name: data.name,
      email: data.email,
      message: data.message,
      company: data.company,
      agree: data.politics,
      branch: data.francaAgency,
    };

    console.log(dataAdapter);

    try {
      const renponseFranca = await axios({
        method: "post",
        url: "https://franca.agency/backend-franca/index.php",
        data: dataAdapter,
      });

      console.log(renponseFranca);
      setValue("politics", false);

      toast.success(renponseFranca.data.message);
      reset();
    } catch (error) {
      // Tipar el error como AxiosError
      if (error instanceof AxiosError) {
        // Acceder a propiedades específicas del error de Axios
        console.error("Error message:", error.message);

        if (error.response) {
          // El servidor respondió con un código de error
          console.error("Response data:", error.response.data.error);
          console.error("Status code:", error.response.status);

          toast.error(error.response.data.error[1]);
        } else if (error.request) {
          // La solicitud se realizó pero no hubo respuesta
          console.error("Request:", error.request);
        } else {
          // Ocurrió otro tipo de error
          console.error("Error:", error.message);
        }
      } else {
        // Si no es un AxiosError, manejar el error genérico
        console.error("Unexpected error:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitEmail)}>
      <div className="formContainerGrid">
        <div className="formContainerGrid_Row">
          <Controller
            name={"name"}
            control={control}
            rules={{ required: "Nombre es requerido" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="standard-basic"
                label="Nombre"
                variant="standard"
                value={value}
                className="textFieldCustom"
                color="success"
                onChange={onChange}
                error={errors.name ? true : false}
                helperText={errors.name?.message}
              />
            )}
          />

          <Controller
            name={"company"}
            control={control}
            rules={{ required: "Compañia es requerido" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="standard-basic"
                label="Compañia"
                variant="standard"
                value={value}
                className="textFieldCustom"
                color="success"
                onChange={onChange}
                error={errors.company ? true : false}
                helperText={errors.company?.message}
              />
            )}
          />
        </div>
        <div className="formContainerGrid_Row">
          <Controller
            name={"email"}
            control={control}
            rules={{ required: "Email es requerido" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                value={value}
                className="textFieldCustom"
                color="success"
                onChange={onChange}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
              />
            )}
          />

          <Controller
            name={"francaAgency"}
            control={control}
            rules={{ required: "Agencia es requerido" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="standard-select-currency"
                select
                label="Agency"
                className="textFieldCustom"
                value={value}
                color="success"
                onChange={onChange}
                variant="standard"
                error={errors.francaAgency ? true : false}
                helperText={errors.francaAgency?.message}
              >
                {agencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    Franca |
                    <p style={{ color: option.color, margin: "2px 4px" }}>
                      {option.label}
                    </p>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>
        <div className="formContainerGrid_Row textArea">
          <Controller
            name={"message"}
            control={control}
            rules={{ required: "Mensaje es requerido" }}
            render={({ field: { onChange, value } }) => (
              <TextField
                className="textFieldCustom"
                id="outlined-multiline-static"
                label="Mensaje"
                color="success"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                error={errors.message ? true : false}
                helperText={errors.message?.message}
              />
            )}
          />
        </div>

        <div className="formContainerGrid_submit">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={
                <Controller
                  name={"politics"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      color="success"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
              }
              label="Aceptar polica de datos"
            />
            <ModalPolitics availablePolitics={availablePolitics} />
          </div>

          <div>
            <Button variant="contained" color="success" type="submit">
              Enviar
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
